<template>
  <section v-if="checkMasterTicket">
    <form-render :form.sync="form" :fields="fields" :key="keyFormRender" @send="onAccept">
      <template #buttonsGroupSlot>
        <div class="button--container-claim">
          <b-button variant="outline-primary" title="Editar" @click="changeDisable" v-show="!flagDisabled">Editar</b-button>
          <b-button variant="outline-danger" title="Cancelar" @click="setDataDefault" v-show="flagDisabled" :disabled="!flagDisabled || checkLoading">Cancelar</b-button>
          <b-button variant="warning" title="Guardar" v-show="flagDisabled" :disabled="!flagDisabled || checkLoading" type="submit">Guardar</b-button>
        </div>
      </template>
    </form-render>
  </section>
</template>
<script>
export default {
  props: ['ticket', 'masterData', 'loading', 'formActive'],
  data() {
    return {
      form: {},
      fields: [],
      keyFormRender: 0,
      flagDisabled: true
    }
  },
  mounted() {
    this.setForm()
  },
  computed: {
    options() {
      return {
        document_type: [
          { id: 'Boleta', text: 'Boleta'},
          { id: 'Factura', text: 'Factura'},
          { id: 'Transbank', text: 'Transbank'},
          { id: 'Otro', text: 'Otro'}
        ],
        formalization_of_reevaluation: [
          { id: 'Formalización inicial', text: 'Formalización inicial'},
          { id: 'Re-estudio 1', text: 'Re-estudio 1'},
          { id: 'Re-estudio 2', text: 'Re-estudio 2'},
          { id: 'Apelación por correo', text: 'Apelación por correo'}
        ],
        reason_code: this.masterData.complaint_reasons.map(el => ({
          id: el.name,
          text: el.name
        }))
      } 
    },
    checkMasterTicket() {
      return !!this.ticket && !!this.masterData
    },
    checkLoading() {
      return this.loading === 'loading'
    }
  },
  watch: {
    formActive(value) {
      if (value !== 'complaint-data' && this.flagDisabled) this.disableFields()
    }
  },
  methods: {
    setForm() {
      this.fields = [
        {fieldType: 'FieldInput', name: 'document_amount', label: 'Monto total documento comercial', containerClass: 'col-12 col-md-6 container-info', type:'number'},
        {fieldType: 'FieldSelect', name: 'document_type', label: 'Tipo documento', containerClass: 'col-12 col-md-6 container-info', options: this.options.document_type},
        {fieldType: 'FieldInput', name: 'distribution_cost', label: 'Monto costo distribución', containerClass: 'col-12 col-md-6 container-info', type:'number'},
        {fieldType: 'FieldInput', name: 'complaint_amount', label: 'Monto reclamo', containerClass: 'col-12 col-md-6 container-info'},
        {fieldType: 'FieldInput', name: 'complaint_number', label: 'Nro. de reclamo courier', containerClass: 'col-12 col-md-6 collapse--space', type:'number'},
        {fieldType: 'FieldSelect', name: 'reason_code', label: 'Motivo del reclamo', containerClass: 'col-12 col-md-6 container-info', options: this.options.reason_code},
        {fieldType: 'FieldInput', name: 'document_number', label: 'Numero de Documento', containerClass: 'col-12 col-md-6 collapse--space', type:'number'},
        {fieldType: 'FieldSelect', name: 'formalization_of_reevaluation', label: 'Fecha numero de reclamo', containerClass: 'col-12 col-md-6 collapse--space', options: this.options.formalization_of_reevaluation},
        {fieldType: 'FieldTextarea', name: 'notes', label: 'Detalle del reclamo', containerClass: 'col-12 col-md-6 container-info', noResize:true},
        {name: 'buttonsGroupSlot', useSlot: true, containerClass: 'col-12'}
      ]
      this.setDataDefault()
    },
    setDataDefault() {
      this.form = {}
      this.fields.map(el => {
        if (this.ticket.complaint_data && el.name !== 'buttonsGroupSlot') {
          if (el.fieldType === 'FieldSelect') {
            this.form[el.name] = this.options[el.name].filter((element) => element.id === this.ticket.complaint_data[el.name])[0]
          } else {
            this.form[el.name] = this.ticket.complaint_data[el.name] ?? null
          }
        } else {
          this.form[el.name] = null
        }
      })
      this.changeDisable()
    },
    changeDisable() {
      this.$emit('editIn', !this.flagDisabled ? 'complaint-data' : 'cancel')
      this.disableFields()
    },
    disableFields() {
      this.fields.map(el => {
        el.disabled = this.flagDisabled
      })
      this.flagDisabled = !this.flagDisabled
      this.keyFormRender++
    },
    onAccept() {
      this.changeDisable()
      const sendData = {
        document_amount: this.form.document_amount,
        document_type: this.form.document_type?.id,
        distribution_cost: this.form.distribution_cost,
        complaint_amount: this.form.complaint_amount,
        complaint_number: this.form.complaint_number,
        reason_code: this.form.reason_code?.id,
        document_number: this.form.document_number,
        formalization_of_reevaluation: this.form.formalization_of_reevaluation?.id,
        notes: this.form.notes
      }
      this.$emit('saveDataTicket', {complaint_data: {...sendData}, overwrite_complaint_data: true})
    }
  }
}
</script>
<style lang="scss">
  .button--container-claim {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
</style>